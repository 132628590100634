import React, { useState, useEffect, useMemo, useRef } from "react";

import { Tabs } from "antd";

import secimLogo from "../assets/images/logos/akp.svg";

import { GithubOutlined } from '@ant-design/icons';

import {
  Card,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

function Tables() {
  // 5 Parti
  const partyNames = ["CHP", "İYİ", "AKP", "MHP", "YSP"];


  const [votes, setVotes] = useState(new Array(partyNames.length).fill(0));
  const [maxSelected, setMaxSelected] = useState([]);
  const [Newselected, setSelected] = useState(new Array(partyNames.length * maxSelected).fill(false));
  const [selectedArray, setSelectedArray] = useState([]);
  const [minSelected, setMinSelected] = useState([]);

  const [cityData, setCityData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [katilim_oran, setKatilimOran] = useState(100);
  const [filteredCities, setFilteredCities] = useState([]);
  const [candidate_akp, setCandidateAkp] = useState([]);
  const [candidate_chp, setCandidateChp] = useState([]);
  const [candidate_mhp, setCandidateMhp] = useState([]);
  const [candidate_hdp, setCandidateHdp] = useState([]);
  const [candidate_iyip, setCandidateiyip] = useState([]);

  const [toplam_secmen, setToplamSecmen] = useState([]);
  const [previousVotes, setPreviousVotes] = useState({});
  const [previousMV, setPreviousMv] = useState({});

  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const response = await fetch("https://rmtdgkkbdxqpsxzshnly.supabase.co/rest/v1/toplam_mv", {
          method: "GET",
          headers: {
            apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJtdGRna2tiZHhxcHN4enNobmx5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzNDMyNDYsImV4cCI6MTk5NjkxOTI0Nn0.Lt-jeG-z8B0gig7NFc8imKvTq71GOs-OrdytkFIUOhc"
          }
        });
        const data = await response.json();

        setCityData(data);

        // console.log(cityData);
      } catch (error) {
        console.error("Error loading city data:", error);
      }
    };

    fetchCityData();
  }, []);


  const handleInputChangeSehir = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    const filtered = cityData
      .map((city) => city.secim_cevre)
      .filter((city) => city.toLowerCase().startsWith(value.toLowerCase()));
    setFilteredCities(filtered);
  };

  const handleInputChangeKatilim = (event) => {
    const value = event.target.value;
    setKatilimOran(value);

  };

  const handleCityClick = (city) => {
    setSearchInput(city);
    setFilteredCities([]);
    // Execute other actions with the selected city if necessary
    const cityObj = cityData.find((item) => item.secim_cevre === city);
    if (cityObj) {
      setMaxSelected(cityObj.toplam_mv);
      setToplamSecmen(cityObj.toplam_secmen);
      getAllCandidates(cityObj.secim_cevre_no);

      partyNames.forEach((parti_no, index) => {
        getPreviousVotes(cityObj.secim_cevre_no, parti_no, index);
      });

      partyNames.forEach((parti_no, index) => {
        getPreviousMv(cityObj.secim_cevre_no, parti_no, index);
      });
    }
    // console.log(maxSelected);
  };

  // Also get the maxSelected value from the value of item

  const getCandidates = async (secim_cevre_no, parti_no, setState) => {
    try {
      const response = await fetch(`https://rmtdgkkbdxqpsxzshnly.supabase.co/rest/v1/mv_list?parti_no=eq.${parti_no}&secim_cevre_no=eq.${secim_cevre_no}`, {
        method: "GET",
        headers: {
          apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJtdGRna2tiZHhxcHN4enNobmx5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzNDMyNDYsImV4cCI6MTk5NjkxOTI0Nn0.Lt-jeG-z8B0gig7NFc8imKvTq71GOs-OrdytkFIUOhc"
        }
      });

      const data = await response.json();
      setState(data);
      // console.log(data);
    } catch (error) {
      console.error("Error loading candidate data:", error);
    }
  };

  const getAllCandidates = async (secim_cevre_no) => {
    try {
      await Promise.all([
        getCandidates(secim_cevre_no, 3, setCandidateAkp),
        getCandidates(secim_cevre_no, 1, setCandidateChp),
        getCandidates(secim_cevre_no, 4, setCandidateMhp),
        getCandidates(secim_cevre_no, 5, setCandidateHdp),
        getCandidates(secim_cevre_no, 2, setCandidateiyip)
      ]);
    } catch (error) {
      console.error("Error loading all candidate data:", error);
    }
  };

  const candidates = useMemo(() => {
    const structuredCandidates = new Array(partyNames.length)
      .fill(0)
      .map(() => []);

    const allCandidates = [
      candidate_chp,
      candidate_iyip,
      candidate_akp,
      candidate_mhp,
      candidate_hdp,
    ];

    allCandidates.forEach((candidatesList, index) => {
      structuredCandidates[index] = candidatesList;
    });

    return structuredCandidates;
  }, [candidate_akp, candidate_chp, candidate_iyip, candidate_hdp, candidate_mhp]);

  const getPreviousVotes = async (secim_cevre_no, parti_no, index) => {
    try {
      const response = await fetch(`https://rmtdgkkbdxqpsxzshnly.supabase.co/rest/v1/vote_2018?parti_no=eq.${index + 1}&secim_cevre_no=eq.${secim_cevre_no}`, {
        method: "GET",
        headers: {
          apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJtdGRna2tiZHhxcHN4enNobmx5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzNDMyNDYsImV4cCI6MTk5NjkxOTI0Nn0.Lt-jeG-z8B0gig7NFc8imKvTq71GOs-OrdytkFIUOhc"
        }
      });

      const data = await response.json();
      setPreviousVotes((prevState) => ({
        ...prevState,
        [index]: data[0]?.vote || 0,
      }));
    } catch (error) {
      console.error("Error loading previous vote data:", error);
    }
  };

  const getPreviousMv = async (secim_cevre_no, parti_no, index) => {
    try {
      const response = await fetch(`https://rmtdgkkbdxqpsxzshnly.supabase.co/rest/v1/mv_sonuc_2018?parti_no=eq.${index + 1}&secim_cevre_no=eq.${secim_cevre_no}`, {
        method: "GET",
        headers: {
          apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJtdGRna2tiZHhxcHN4enNobmx5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzNDMyNDYsImV4cCI6MTk5NjkxOTI0Nn0.Lt-jeG-z8B0gig7NFc8imKvTq71GOs-OrdytkFIUOhc"
        }
      });

      const data = await response.json();
      setPreviousMv((prevState) => ({
        ...prevState,
        [index]: data[0]?.mv_sonuc || 0,
      }));
    } catch (error) {
      console.error("Error loading previous vote data:", error);
    }
  };

  // console.log(previousVotes);


  function dhondt(partiesPercentages, seats) {
    const partyCount = partiesPercentages.length;
    const seatsAllocated = new Array(partyCount).fill(0);
    const quotients = [...partiesPercentages];

    for (let i = 0; i < seats; i++) {
      const maxQuotientIndex = quotients.indexOf(Math.max(...quotients));
      seatsAllocated[maxQuotientIndex]++;
      quotients[maxQuotientIndex] = partiesPercentages[maxQuotientIndex] / (seatsAllocated[maxQuotientIndex] + 1);
      console.log(quotients[maxQuotientIndex]);
    }

    // Get the selected quotients
    const selectedQuotients = [];
    for (let i = 0; i < partyCount; i++) {
      for (let j = 0; j < seatsAllocated[i]; j++) {
        selectedQuotients.push(partiesPercentages[i] / (j + 1));
      }
    }
    setMinSelected(Math.min(...selectedQuotients));
    return seatsAllocated;
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const index = parseInt(name.replace("party", "")) - 1;

    const newVotes = [...votes];
    newVotes[index] = parseFloat(value);
    setVotes(newVotes);
    // console.log(newVotes);

    const selectedPersonNumbers = dhondt(newVotes, maxSelected);
    setSelectedArray(selectedPersonNumbers);
    console.log(selectedPersonNumbers);
    // Initialize selected array
    const newSelected = new Array(partyNames.length * 2).fill(false);

    // Loop through each party
    for (let i = 0; i < partyNames.length; i++) {
      let seatsAllocated = selectedPersonNumbers[i];
      // Color the cells green based on the number of seats allocated
      for (let j = 0; j < seatsAllocated; j++) {
        newSelected[i + (j * partyNames.length)] = true;
      }
    }
    setSelected(newSelected);
  };

  // Çok Partili Fonksiyonlar

  const partyNames2 = ["CHP", "İYİ", "AKP", "MHP", "YSP", "TİP", "BBP", "YRP", "ZP", "MP"];
  const [votes2, setVotes2] = useState(new Array(partyNames2.length).fill(0));
  const [Newselected2, setSelected2] = useState(new Array(partyNames2.length * maxSelected).fill(false));
  const [selectedArray2, setSelectedArray2] = useState([]);
  const [overThreshold, setOverThreshold] = useState(new Array(partyNames2.length).fill(true));
  const [minSelected2, setMinSelected2] = useState([]);


  const handleCityClick2 = (city) => {
    setSearchInput(city);
    setFilteredCities([]);
    // Execute other actions with the selected city if necessary
    const cityObj = cityData.find((item) => item.secim_cevre === city);
    if (cityObj) {
      setMaxSelected(cityObj.toplam_mv);
      setToplamSecmen(cityObj.toplam_secmen);
      getAllCandidates(cityObj.secim_cevre_no);

      partyNames2.forEach((parti_no, index) => {
        getPreviousVotes(cityObj.secim_cevre_no, parti_no, index);
      });

      partyNames2.forEach((parti_no, index) => {
        getPreviousMv(cityObj.secim_cevre_no, parti_no, index);
      });
    }
    // console.log(maxSelected);
  };

  function dhondt2(partiesPercentages2, seats, newOverThreshold) {
    const partyCount2 = partiesPercentages2.length;
    const seatsAllocated2 = new Array(partyCount2).fill(0);
    const quotients2 = [...partiesPercentages2];

    // Set quotients to 0 for parties that are not over the threshold
    for (let i = 0; i < partyCount2; i++) {
      if (!newOverThreshold[i]) {
        quotients2[i] = 0;
      }
    }

    for (let i = 0; i < seats; i++) {
      const maxQuotientIndex2 = quotients2.indexOf(Math.max(...quotients2));
      seatsAllocated2[maxQuotientIndex2]++;
      quotients2[maxQuotientIndex2] = partiesPercentages2[maxQuotientIndex2] / (seatsAllocated2[maxQuotientIndex2] + 1);
    }

    // Get the selected quotients
    const selectedQuotients2 = [];
    for (let i = 0; i < partyCount2; i++) {
      for (let j = 0; j < seatsAllocated2[i]; j++) {
        selectedQuotients2.push(partiesPercentages2[i] / (j + 1));
      }
    }

    setMinSelected2(Math.min(...selectedQuotients2));
    return seatsAllocated2;
  }



  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
    const index = parseInt(name.replace("party", "")) - 1;

    const newVotes2 = [...votes2];
    newVotes2[index] = parseFloat(value);
    setVotes2(newVotes2);

    updateSeatAllocation(newVotes2, overThreshold);

  };

  // New function to update seat allocation
  const updateSeatAllocation = (newVotes, newOverThreshold) => {
    const newVotesWithThreshold = newVotes.map((vote, i) =>
      newOverThreshold[i] ? vote : 0
    );
    // console.log("newVotesWithThreshold", newVotesWithThreshold);
    const selectedPersonNumbers2 = dhondt2(newVotesWithThreshold, maxSelected, newOverThreshold);
    setSelectedArray2(selectedPersonNumbers2);

    // Initialize selected array
    const newSelected2 = new Array(partyNames2.length * maxSelected).fill(false);

    // Loop through each party
    for (let i = 0; i < partyNames2.length; i++) {
      let seatsAllocated2 = selectedPersonNumbers2[i];
      // Color the cells green based on the number of seats allocated
      for (let j = 0; j < seatsAllocated2; j++) {
        newSelected2[i + (j * partyNames2.length)] = newOverThreshold[i];
      }
    }
    setSelected2(newSelected2);
  };

  // Update the handleThresholdClick function to use the updateSeatAllocation function
  const handleThresholdClick = (index) => {
    // Update overThreshold state
    setOverThreshold((prevOverThreshold) => {
      const newOverThreshold = [...prevOverThreshold];
      newOverThreshold[index] = !newOverThreshold[index];
      updateSeatAllocation(votes2, newOverThreshold);

      return newOverThreshold;
    });
  };

  // console.log("selectedArray2", selectedArray2);
  // console.log(Newselected2);



  // Image
  const party_logo_url = ["https://upload.wikimedia.org/wikipedia/commons/e/ef/Cumhuriyet_Halk_Partisi_Logo.svg",
    "https://upload.wikimedia.org/wikipedia/commons/8/84/%C4%B0Y%C4%B0_Party_square_logo.svg",
    "https://upload.wikimedia.org/wikipedia/commons/3/36/AK_PARTI_Logo.svg",
    "https://raw.githubusercontent.com/nuuritas/dhont_sim/aeb8c71838825af26497a50c2d2915e893ec5d89/logos/mhp.svg",
    "https://upload.wikimedia.org/wikipedia/tr/e/eb/Ye%C5%9Filler_ve_Sol_Gelecek_Partisi_logo.png",
    "https://upload.wikimedia.org/wikipedia/commons/7/76/T%C3%BCrkiye_%C4%B0%C5%9F%C3%A7i_Partisi.svg",
    "https://upload.wikimedia.org/wikipedia/tr/7/7c/B%C3%BCy%C3%BCk_Birlik_Partisi_logo.png",
    "https://upload.wikimedia.org/wikipedia/commons/0/0a/Yeniden_Refah_Partisi_logo.svg",
    "https://upload.wikimedia.org/wikipedia/tr/9/96/Zafer_Partisi_Logo.png",
    "https://upload.wikimedia.org/wikipedia/tr/d/d1/Memleket_Partisi_logo.png"]

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generatedImageURL, setGeneratedImageURL] = useState(null);
  const generatedImageRef = useRef(null);
  const party_colors = ["rgb(216, 12, 18)", "rgb(74, 180, 230)", "rgb(248, 152, 28)",
    "rgb(237, 28, 36)",
    "rgb(48, 141, 57)",
    "rgb(189, 18, 24)",
    "rgb(238, 22, 31)",
    "rgb(238, 23, 31)",
    "rgb(192, 13, 14)",
    "rgb(24, 95, 167)",


  ]

  const generateImage = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = 2400;
    canvas.height = 1400;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#FFF5E9";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw header
    ctx.fillStyle = "#2674b8";
    ctx.fillRect(0, 0, canvas.width, canvas.height * 0.1);

    ctx.fillStyle = "#FFF";
    ctx.font = "bold 72px Montserrat";
    ctx.fillText(`${searchInput} |   Mv. Seçim Simülasyonu`, canvas.width * 0.1, canvas.height * 0.07);

    // link icon
    const linklogo = new Image();
    linklogo.src = "https://raw.githubusercontent.com/nuuritas/dhont_sim/main/Daco_5096184.png";
    linklogo.crossOrigin = 'anonymous';
    await new Promise(resolve => linklogo.onload = resolve);
    ctx.drawImage(linklogo,
      canvas.width * 0.845,
      canvas.height * 0.03,
      75, 75);

    // Draw the logo
    ctx.fillStyle = "#FFF";
    ctx.font = "bold 60px Montserrat";
    ctx.fillText("secim.me", canvas.width * 0.88, canvas.height * 0.07);

    // Draw main part
    const maxValue = Math.max(...selectedArray);
    const maxVote = minSelected * (maxValue + 1);
    const barWidth = canvas.width * 1 / partyNames.length / 2;
    const barSpacing = barWidth / 4;
    const xOffset = canvas.width * 0.03;
    const yOffset = canvas.height * 0.15;

    for (let i = 0; i < partyNames.length; i++) {
      // Load and draw party logo
      const partyLogo = new Image();
      partyLogo.src = party_logo_url[i];
      partyLogo.crossOrigin = 'anonymous';
      await new Promise(resolve => partyLogo.onload = resolve);
      ctx.drawImage(partyLogo,
        xOffset + i * (barWidth + barSpacing) + barWidth * 0.1,
        yOffset * 0.75,
        barWidth * 0.8,
        canvas.height * 0.09);

      ctx.fillStyle = "#000";
      ctx.font = "48px Montserrat";
      ctx.fillText("%" + votes[i],
        xOffset + i * (barWidth + barSpacing) + 45,
        canvas.height * 0.1 + yOffset);

      ctx.fillText(selectedArray[i] + " Mv.",
        xOffset + i * (barWidth + barSpacing) + 45,
        canvas.height * 0.14 + yOffset);

      // Empty soft bars.
      const barpercentage = (votes[i] / maxVote);
      const barytop = 450;
      const barybottom = 1300;
      const barmaxheight = barybottom - barytop;
      const softColor = party_colors[i].replace(')', ', 0.2)').replace('rgb', 'rgba');
      ctx.fillStyle = softColor;
      ctx.fillRect(
        xOffset + i * (barWidth + barSpacing),
        barytop,
        barWidth,
        barmaxheight
      );

      // Fill the bars.
      ctx.fillStyle = party_colors[i];
      ctx.fillRect(
        xOffset + i * (barWidth + barSpacing),
        barytop + barmaxheight * (1 - barpercentage),
        barWidth,
        barmaxheight * barpercentage
      );

      // Threshold lines
      for (let line = 0; line <= maxValue; line++) {
        const lineHeight = barytop + ((line) * (barmaxheight / (maxValue + 1)));
        ctx.strokeStyle = "#000";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(xOffset + i * (barWidth + barSpacing), lineHeight);
        ctx.lineTo(xOffset + i * (barWidth + barSpacing) + barWidth, lineHeight);
        ctx.stroke();

        ctx.fillStyle = "#000";
        // Make font size dynamic based on maxValue
        ctx.font = maxValue >= 10 ? "28px Montserrat" : "36px Montserrat";
        ctx.fillText(candidates[i][maxValue-line].ad,
          // maxValue - line + 1,
          xOffset + i * (barWidth + barSpacing),
          lineHeight + (barmaxheight / maxValue / 2.6));

        ctx.fillText(candidates[i][maxValue-line].soyad,
          // maxValue - line + 1,
          xOffset + i * (barWidth + barSpacing),
          lineHeight + (barmaxheight / maxValue / 1.4));


      }
    }

        // Threshold values
        const barytop = 450;
        const barybottom = 1300;
        const barmaxheight = barybottom - barytop;
        for (let i = 0; i <= maxValue + 1; i++) {
    
          ctx.fillStyle = "#000";
          ctx.font = "42px Montserrat";
    
          const ytop = barytop + ((i) * (barmaxheight / (maxValue + 1))); // Burda 0 olacak
          ctx.fillText((minSelected * (maxValue - (i - 1))).toFixed(2), // Burda 1 olacak
            canvas.width * 0.8 - canvas.width * 0.16,
            ytop * 1.01);
        }



    // Draw previous votes part
    ctx.fillStyle = "#000";
    ctx.font = "60px Montserrat Bold";
    ctx.fillText("2018 Sonuçları",
      canvas.width * 0.8 + canvas.width * 0.01,
      yOffset * 0.95);

    for (let i = 0; i < partyNames.length; i++) {
      const x = canvas.width * 0.8 + canvas.width * 0.01;
      const y = yOffset + 30 + i * 120;

      // Load and draw party logo
      const partyLogo = new Image();
      partyLogo.src = party_logo_url[i];
      partyLogo.crossOrigin = 'anonymous';
      await new Promise(resolve => partyLogo.onload = resolve);
      ctx.drawImage(partyLogo, x, y, 100, 100);

      // Draw soft-colored full height bar
      const softColor = party_colors[i].replace(')', ', 0.2)').replace('rgb', 'rgba');
      ctx.fillStyle = softColor;
      ctx.fillRect(
        x + 120,
        y,
        100,
        100
      );
      // Fill previous vote bars
      ctx.fillStyle = party_colors[i];
      ctx.fillRect(x + 120, y, previousVotes[i] * 2, 100);

      // Draw previous MV
      ctx.fillStyle = "#000";
      ctx.font = "48px Montserrat";
      ctx.fillText("%" + previousVotes[i], x + 120 + 120, y + 40);

      ctx.fillStyle = "#000";
      ctx.font = "48px Montserrat";
      ctx.fillText(previousMV[i] + "Mv.", x + 120 + 140, y + 90);
    }

    const githublogo = new Image();
    githublogo.src = "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg";
    githublogo.crossOrigin = 'anonymous';
    await new Promise(resolve => githublogo.onload = resolve);
    ctx.drawImage(githublogo,
      canvas.width * 0.86,
      canvas.height * 0.92,
      75, 75);

    ctx.fillStyle = "#000";
    ctx.font = "48px Montserrat";
    ctx.fillText("nuuritas", canvas.width * 0.9, canvas.height * 0.96);

    // Save and return the image
    const base64ImageURL = canvas.toDataURL("image/png");
    return base64ImageURL;
  };


  const handleButtonClick = async () => {
    const imageURL = await generateImage();
    const downloadLink = document.createElement('a');
    downloadLink.href = imageURL;
    downloadLink.download = 'secim_me_sim.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };


  // Çok Partili Image
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [generatedImageURL2, setGeneratedImageURL2] = useState(null);
  const generatedImageRef2 = useRef(null);

  const generateImage2 = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = 2400;
    canvas.height = 1400;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "#FFF5E9";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw header
    ctx.fillStyle = "#2674b8";
    ctx.fillRect(0, 0, canvas.width, canvas.height * 0.1);

    ctx.fillStyle = "#FFF";
    ctx.font = "bold 72px Montserrat";
    ctx.fillText(`${searchInput} |   Mv. Seçim Simülasyonu`, canvas.width * 0.1, canvas.height * 0.07);

    // link icon
    const linklogo = new Image();
    linklogo.src = "https://raw.githubusercontent.com/nuuritas/dhont_sim/main/Daco_5096184.png";
    linklogo.crossOrigin = 'anonymous';
    await new Promise(resolve => linklogo.onload = resolve);
    ctx.drawImage(linklogo,
      canvas.width * 0.845,
      canvas.height * 0.03,
      75, 75);

    // Draw the logo
    ctx.fillStyle = "#FFF";
    ctx.font = "bold 60px Montserrat";
    ctx.fillText("secim.me", canvas.width * 0.88, canvas.height * 0.07);

    // Draw main part
    const maxValue2 = Math.max(...selectedArray2);
    const maxVote2 = minSelected2 * (maxValue2 + 1);
    const barWidth = canvas.width * 0.9 / partyNames2.length / 1.6;
    const barSpacing = barWidth / 4;
    const xOffset = canvas.width * 0.03;
    const yOffset = canvas.height * 0.15;
    

    for (let i = 0; i < partyNames2.length; i++) {
      // Load and draw party logo
      const partyLogo = new Image();
      partyLogo.src = party_logo_url[i];
      partyLogo.crossOrigin = 'anonymous';
      await new Promise(resolve => partyLogo.onload = resolve);
      ctx.drawImage(partyLogo,
        xOffset + i * (barWidth + barSpacing) + barWidth * 0.13,
        yOffset * 0.75,
        barWidth * 0.8,
        canvas.height * 0.09);

      ctx.fillStyle = "#000";
      ctx.font = "48px Montserrat";
      ctx.fillText("%" + votes2[i],
        xOffset + i * (barWidth + barSpacing) + 10,
        canvas.height * 0.09 + yOffset);

      ctx.fillText(selectedArray2[i] + " Mv.",
        xOffset + i * (barWidth + barSpacing) + 10,
        canvas.height * 0.13 + yOffset);

      // Empty soft bars.
      const barpercentage = (votes2[i] / maxVote2);
      const barytop = 450;
      const barybottom = 1300;
      const barmaxheight = barybottom - barytop;
      const softColor = party_colors[i].replace(')', ', 0.2)').replace('rgb', 'rgba');
      ctx.fillStyle = softColor;
      ctx.fillRect(
        xOffset + i * (barWidth + barSpacing),
        barytop,
        barWidth,
        barmaxheight
      );

      // Fill the bars.
      ctx.fillStyle = party_colors[i];
      ctx.fillRect(
        xOffset + i * (barWidth + barSpacing),
        barytop + barmaxheight * (1 - barpercentage),
        barWidth,
        barmaxheight * barpercentage
      );

      // Threshold lines
      for (let line = 0; line <= maxValue2; line++) {
        const lineHeight = barytop + ((line) * (barmaxheight / (maxValue2 + 1)));
        ctx.strokeStyle = "#000";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(xOffset + i * (barWidth + barSpacing), lineHeight);
        ctx.lineTo(xOffset + i * (barWidth + barSpacing) + barWidth, lineHeight);
        ctx.stroke();

        ctx.fillStyle = "#000";
        ctx.font = "48px Montserrat";
        ctx.fillText(maxValue2 - line + 1,
          xOffset + i * (barWidth + barSpacing) + 50,
          lineHeight + (barmaxheight / maxValue2 / 1.7));
      }

    }

    // Threshold values
    const barytop = 450;
    const barybottom = 1300;
    const barmaxheight = barybottom - barytop;
    for (let i = 0; i <= maxValue2 + 1; i++) {

      ctx.fillStyle = "#000";
      ctx.font = "36px Montserrat";

      const ytop = barytop + ((i) * (barmaxheight / (maxValue2 + 1))); // Burda 0 olacak
      ctx.fillText((minSelected2 * (maxValue2 - (i - 1))).toFixed(2), // Burda 1 olacak
        canvas.width * 0.8 - canvas.width * 0.07,
        ytop * 1.01);
    }



    // Draw previous votes part
    ctx.fillStyle = "#000";
    ctx.font = "60px Montserrat Bold";
    ctx.fillText("2018 Sonuçları",
      canvas.width * 0.8 + canvas.width * 0.01,
      yOffset * 0.95);

    for (let i = 0; i < partyNames.length; i++) {
      const x = canvas.width * 0.8 + canvas.width * 0.01;
      const y = yOffset + 30 + i * 120;

      // Load and draw party logo
      const partyLogo = new Image();
      partyLogo.src = party_logo_url[i];
      partyLogo.crossOrigin = 'anonymous';
      await new Promise(resolve => partyLogo.onload = resolve);
      ctx.drawImage(partyLogo, x, y, 100, 100);

      // Draw soft-colored full height bar
      const softColor = party_colors[i].replace(')', ', 0.2)').replace('rgb', 'rgba');
      ctx.fillStyle = softColor;
      ctx.fillRect(
        x + 120,
        y,
        100,
        100
      );
      // Fill previous vote bars
      ctx.fillStyle = party_colors[i];
      ctx.fillRect(x + 120, y, previousVotes[i] * 2, 100);

      // Draw previous MV
      ctx.fillStyle = "#000";
      ctx.font = "48px Montserrat";
      ctx.fillText("%" + previousVotes[i], x + 120 + 120, y + 40);

      ctx.fillStyle = "#000";
      ctx.font = "48px Montserrat";
      ctx.fillText(previousMV[i] + "Mv.", x + 120 + 140, y + 90);
    }

    const githublogo = new Image();
    githublogo.src = "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg";
    githublogo.crossOrigin = 'anonymous';
    await new Promise(resolve => githublogo.onload = resolve);
    ctx.drawImage(githublogo,
      canvas.width * 0.86,
      canvas.height * 0.92,
      75, 75);

    ctx.fillStyle = "#000";
    ctx.font = "48px Montserrat";
    ctx.fillText("nuuritas", canvas.width * 0.9, canvas.height * 0.96);

    // Save and return the image
    const base64ImageURL = canvas.toDataURL("image/png");
    return base64ImageURL;
  };

  const handleButtonClick2 = async () => {
    const imageURL2 = await generateImage2();
    const downloadLink = document.createElement('a');
    downloadLink.href = imageURL2;
    downloadLink.download = 'secim_me_sim.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  return (
    <>
      <Tabs defaultActiveKey="1"
        onChange={callback}
        style={{ width: "100%" }}>
        <TabPane tab="5 Ana Parti" key="1">
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card>
                  <center>
                    <FormGroup>
                      <CardTitle tag="h4">D'Hont Simülatörü</CardTitle>
                      <b><Label for="citySearch">Seçim Bölgesi: </Label></b>
                      <Input
                        type="text"
                        name="citySearch"
                        id="citySearch"
                        placeholder="Şehir Adı Yazınız.."
                        value={searchInput}
                        onChange={handleInputChangeSehir}
                      />
                    </FormGroup>
                    <ListGroup>
                      {filteredCities.map((city, index) => (
                        <ListGroupItem
                          key={index}
                          tag="button"
                          action
                          onClick={() => handleCityClick(city)}
                        >
                          {city}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </center>
                  <CardHeader>
                    <center>
                      <p className="category">
                        Toplam Mv. Sayısı: <b>{maxSelected}</b>
                        <br></br>
                        Toplam Seçmen Sayısı: <b>{toplam_secmen}</b>
                        <br></br>
                        <b><Label for="katilim_oran">Katılım Oranı Beklentisi (%): </Label></b>
                        <Input
                          type="number"
                          name="Seçime Katılım Oranı(%)"
                          id="katilim_oran"
                          placeholder="%"
                          min="0"
                          max="100"
                          step="1"
                          value={katilim_oran}
                          onChange={handleInputChangeKatilim}
                        />
                        <br></br>
                        Bir Mv. İçin Gereken Min. Oy Sayısı: <b>{(((toplam_secmen * (katilim_oran / 100)) * ((100 / maxSelected) / 100)) / 1000).toFixed(0)} Bin </b>
                      </p>
                    </center>
                  </CardHeader>
                  <div className="table-header-line"></div> {/* Added line */}
                  <div className="table-responsive">
                    <center>(Parantez İçi İfadeler 2018 Oy Oranı ve Mv. Sayılarını Gösterir.)</center>
                    <center>Toplam Eklediğiniz Oy (%) : <b>{votes.reduce((a, b) => a + b, 0).toFixed(2)}</b> ; Eksik Kalan Oy (%): <b>{(100 - votes.reduce((a, b) => a + b, 0)).toFixed(2)}</b> </center>
                    <center>(Oylarınızı ekledikten sonra aşağıdaki görseli indirerek paylaşabilirsiniz.)</center>
                    <div>
                      <center><button onClick={handleButtonClick}>Görsel Oluştur</button></center>
                      {isModalOpen && (
                        <div className="modal">
                          <div className="modal-content">
                            <span className="close" onClick={closeModal}>
                              &times;
                            </span>
                            {generatedImageURL && <img id="generatedImage" ref={generatedImageRef} src={generatedImageURL} alt="Generated" />}
                          </div>
                        </div>
                      )}
                    </div>
                    
                    <Table>
                      <thead>
                        <tr>
                          {partyNames.map((name, index) => (
                            <th key={index}>{name} <br />(%{previousVotes[index]}-Mv:{previousMV[index]})</th>
                          ))}
                        </tr>
                        <tr>
                          {partyNames.map((name, index) => (
                            <td key={index}><center>Oy(%):
                              <input
                                type="number"
                                name={`party${index + 1}`}
                                min="0"
                                max="100"
                                step="1"
                                value={votes[index]}
                                onChange={handleInputChange}
                              />
                              <br />
                              <b>{selectedArray[index]} Mv.</b>
                            </center>
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Array(maxSelected)
                          .fill()
                          .map((_, rowIndex) => (
                            <tr key={rowIndex}>
                              {partyNames.map((_, partyIndex) => (
                                <td
                                  key={partyIndex}
                                  style={{
                                    backgroundColor: Newselected[
                                      rowIndex * partyNames.length + partyIndex
                                    ]
                                      ? "#98BF64"
                                      : "",
                                  }}
                                >
                                  {candidates[partyIndex]?.[rowIndex]?.ad_soyad} ({(votes[partyIndex] / (rowIndex + 1)).toFixed(1)})
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane tab="Tüm Partiler" key="2">
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card>
                  <center>
                    <FormGroup>
                      <CardTitle tag="h4">D'Hont Simülatörü</CardTitle>
                      <b><Label for="citySearch">Seçim Bölgesi: </Label></b>
                      <Input
                        type="text"
                        name="citySearch"
                        id="citySearch"
                        placeholder="Şehir Adı Yazınız.."
                        value={searchInput}
                        onChange={handleInputChangeSehir}
                      />
                    </FormGroup>
                    <ListGroup>
                      {filteredCities.map((city, index) => (
                        <ListGroupItem
                          key={index}
                          tag="button"
                          action
                          onClick={() => handleCityClick2(city)}
                        >
                          {city}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </center>
                  <CardHeader>
                    <center>
                      <p className="category">
                        Toplam Mv. Sayısı: <b>{maxSelected}</b>
                        <br></br>
                        Toplam Seçmen Sayısı: <b>{toplam_secmen}</b>
                        <br></br>
                        <b><Label for="katilim_oran">Katılım Oranı Beklentisi (%): </Label></b>
                        <Input
                          type="number"
                          name="Seçime Katılım Oranı(%)"
                          id="katilim_oran"
                          placeholder="%"
                          min="0"
                          max="100"
                          step="0.1"
                          value={katilim_oran}
                          onChange={handleInputChangeKatilim}
                        />
                        <br></br>
                        Bir Mv. İçin Gereken Min. Oy Sayısı: <b>{(((toplam_secmen * (katilim_oran / 100)) * ((100 / maxSelected) / 100)) / 1000).toFixed(0)} Bin </b>
                      </p>
                    </center>
                  </CardHeader>
                  <div className="table-header-line"></div> {/* Added line */}
                  <div className="table-responsive">
                    
                    <center>(✅ ❌ İkonları baraj durumunu gösterir, tıkladıkça değişir.)</center>
                    <center>(Parantez İçi İfadeler 2018 Oy Oranı ve Mv. Sayılarını Gösterir.)</center>
                    <center>Toplam Eklediğiniz Oy (%) : <b>{votes2.reduce((a, b) => a + b, 0).toFixed(2)}</b> ; Eksik Kalan Oy (%): <b>{(100 - votes2.reduce((a, b) => a + b, 0)).toFixed(2)}</b> </center>
                    <center>(Oylarınızı ekledikten sonra aşağıdaki görseli indirerek paylaşabilirsiniz.)</center>
                    <div>
                      <center><button onClick={handleButtonClick2}>Görsel Oluştur</button></center>
                      {isModalOpen2 && (
                        <div className="modal">
                          <div className="modal-content">
                            <span className="close" onClick={closeModal2}>
                              &times;
                            </span>
                            {generatedImageURL2 && <img id="generatedImage" ref={generatedImageRef2} src={generatedImageURL2} alt="Generated" />}
                          </div>
                        </div>
                      )}
                    </div>
                    <Table>
                      <thead>
                        <tr>
                          {partyNames2.map((name, index) => (
                            <th key={index}>{name} <Button
                              size="sm"
                              style={{ padding: "0px 0px", backgroundColor: "transparent", border: "none" }}
                              onClick={() => handleThresholdClick(index)}
                            >

                              {overThreshold[index] ? "\u2705" : "\u274c"}
                            </Button><br />(%{previousVotes[index]}-Mv:{previousMV[index]})</th>
                          ))}
                        </tr>
                        <tr>
                          {partyNames2.map((name, index) => (
                            <td key={index}><center>Oy (%):
                              <input
                                type="number"
                                name={`party${index + 1}`}
                                min="0"
                                max="100"
                                step="0.1"
                                value={votes2[index]}
                                onChange={handleInputChange2}
                              />
                              <br />
                              <b>{selectedArray2[index]} Mv.</b>
                            </center>
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Array(maxSelected)
                          .fill()
                          .map((_, rowIndex) => (
                            <tr key={rowIndex}>
                              {partyNames2.map((_, partyIndex) => (
                                <td
                                  key={partyIndex}
                                  style={{
                                    backgroundColor: Newselected2[
                                      rowIndex * partyNames2.length + partyIndex
                                    ]
                                      ? "#98BF64"
                                      : "",
                                  }}
                                >
                                  {[rowIndex + 1]}.Mv ({(votes2[partyIndex] / (rowIndex + 1)).toFixed(2)})
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Tables;