import { Layout} from "antd";
import Footer from "./Footer";

const {Content} = Layout;

function Main({ children }) {
  return (
    <Layout className="layout-ant">
      <Layout>
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
